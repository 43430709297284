import React, { Dispatch, ReactNode, SetStateAction } from "react";
import CustomDialog from "../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../translate/i18n";
import { Button } from "@mui/material";
import LoadingButtonIcon from "../../../components/LoadingButtonIcon";
import { ActionButton } from "../../../domain/types/ActionButton";

type Props = {
	isDialogOpen: boolean;
	isUpdatingStatus: boolean;
	isCancellationStatus: boolean;
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
	updateStatus: (
		statusBody: { tryToChangeSeller?: boolean } | null
	) => Promise<void>;
	isDelivery: boolean;
};

export default function ChangeStatusDialog({
	isDialogOpen,
	isUpdatingStatus,
	isCancellationStatus,
	setIsDialogOpen,
	updateStatus,
	isDelivery,
}: Props) {
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const enableChangeSeller = configurations?.enableChangeSeller;

	const renderDialogInfo = () => {
		if (!isCancellationStatus)
			return { title: i18n.t("legacyOrderProgress.ChangeStatusDialogTitle") };
		return {
			title: i18n.t("legacyOrderProgress.CancellationStatusDialogTitle"),
			subtitle: i18n.t("legacyOrderProgress.CancellationStatusDialogSubtitle"),
			...((!enableChangeSeller ||
				localStorage.getItem("role") === "3" ||
				!isDelivery) && { titleStyles: { marginRight: "32px" } }),
		};
	};

	const changeSellerActions = () => {
		const showChangeSellerBtn =
			localStorage.getItem("role") !== "3" && isDelivery && enableChangeSeller;
		return (
			<>
				{showChangeSellerBtn && (
					<Button
						variant="contained"
						color="info"
						onClick={() => updateStatus({ tryToChangeSeller: true })}
						disabled={isUpdatingStatus}
					>
						{i18n.t("legacyOrderProgress.ChangeSellerAction").toString()}
					</Button>
				)}
				<Button
					variant="contained"
					color="danger"
					onClick={() => {
						if (!enableChangeSeller) {
							updateStatus(null);
							return;
						}
						updateStatus({
							tryToChangeSeller:
								localStorage.getItem("role") === "3" && isDelivery,
						});
					}}
					disabled={isUpdatingStatus}
				>
					{isUpdatingStatus ? (
						<LoadingButtonIcon />
					) : (
						i18n.t("legacyOrderProgress.CancelOrderButton").toString()
					)}
				</Button>
			</>
		);
	};

	const renderButtonsProps = ():
		| { actionButton: ActionButton }
		| { customActions: ReactNode } => {
		if (!isCancellationStatus)
			return {
				actionButton: {
					text: i18n.t(`buttons.General.BtnConfirm`),
					variant: "contained",
					color: "success",
					isDisabled: isUpdatingStatus,
					isLoading: isUpdatingStatus,
					onClick: () => updateStatus(null),
				},
			};

		return { customActions: changeSellerActions() };
	};

	return (
		<CustomDialog
			isOpen={isDialogOpen}
			setIsOpen={setIsDialogOpen}
			showCloseButton={isCancellationStatus}
			dialogInfo={renderDialogInfo()}
			{...renderButtonsProps()}
		/>
	);
}
